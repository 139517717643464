<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        ผู้ใช้
        <v-icon>mdi-chevron-right</v-icon>
        จัดการผู้ใช้
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- user -->
    <v-dialog
      v-model="staffBranch.dialog"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
          <span v-if="!staffBranch.isUpdate" class="ml-3">เพิ่มผู้ใช้</span>
          <span v-else class="ml-3">แก้ไขผู้ใช้</span>
          <v-spacer />
          <v-btn icon @click="CloseDialog()"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="formStaffBranch" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3>ชื่อผู้ใช้ / รหัสผ่าน</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> รูปภาพ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <a id="pick-avatar">
                  <v-avatar
                    v-if="photo.photo_link != null && photo.photo_link != ''"
                    class="picsize-hover"
                    tile
                  >
                    <img :src="photo.photo_link" class="picsize" />
                  </v-avatar>
                  <v-avatar v-else class="picsize-hover" tile> No Picture </v-avatar>
                </a>
                <avatar-cropper
                  @uploading="handleUploading"
                  @uploaded="handleUploaded"
                  @completed="handleCompleted"
                  @error="handlerError"
                  :cropper-options="cropperOptions"
                  :labels="textButton"
                  :upload-url="uploadurl"
                  trigger="#pick-avatar"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.name"
                  @change="(value) => (staffBranch.name = Trim_value(value))"
                  ref="staffBranchname"
                  @keyup.enter="$refs.staffBranchphone.focus()"
                  :rules="staffBranch.rulesName"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> เบอร์โทร : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.phone"
                  @change="(value) => (staffBranch.phone = Trim_value(value))"
                  ref="staffBranchphone"
                  @keyup.enter="$refs.staffBranchusername.focus()"
                  :rules="staffBranch.rulesPhone"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  maxlength="10"
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffBranch.id == 0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อผู้ใช้ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.username"
                  @change="(value) => (staffBranch.username = Trim_value(value))"
                  ref="staffBranchusername"
                  @keyup.enter="$refs.staffBranchpassword.focus()"
                  :rules="staffBranch.rulesUsername"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffBranch.id == 0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> รหัสผ่าน : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.password"
                  @change="(value) => (staffBranch.password = Trim_value(value))"
                  ref="staffBranchpassword"
                  @keyup.enter="$refs.staffBranchpassword2.focus()"
                  :rules="staffBranch.rulesPassword"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffBranch.id == 0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ยืนยัน่รหัสผ่าน : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.password2"
                  @change="(value) => (staffBranch.password2 = Trim_value(value))"
                  ref="staffBranchpassword2"
                  :rules="staffBranch.rulesPassword2"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password2 ? 'text' : 'password'"
                  @click:append="show_password2 = !show_password2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> สถานะ : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-switch
                  v-model="staffBranch.isActive"
                  :disabled="staffBranch.isReadonly || !staffPermission.AddUser"
                  color="success"
                  class="mx-2"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span v-if="staffBranch.isActive">เปิดใช้งาน</span>
                <span v-else>ไม่เปิดใช้งาน</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ผู้ใช้หลัก : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-checkbox
                  v-model="staffBranch.isMasterUser"
                  :disabled="staffBranch.isReadonly || !LoginstaffBranch.isMasterUser"
                  color="info"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span
                  v-if="staffBranch.isReadonly && staffBranch.isUpdate"
                  class="text-danger"
                  >ไม่สามารถแก้ไขได้ !!</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3>สิทธิ์ผู้ใช้</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in branchPermission"
                :key="index"
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-checkbox
                  v-model="item.isActive"
                  :disabled="!(LoginstaffBranch.isMasterUser || staffPermission.AddUser)"
                  class="ml-4"
                  color="success"
                  :label="item.nameTh"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-divider class="pb-6"></v-divider>

            <v-row>
              <v-col ols="12" md="3" class="pt-1 pb-2">
                <span>เลือกแผนก : </span>
              </v-col>
              <v-col  cols="12" md="9" class="pt-1 pb-2">
                <v-select
                  v-model="staffBranch.department"
                  autocomplete="off"
                  :value="staffBranch.department"
                  @change="updateChangeDepartment(staffBranch.department)"
                  :items="dataset_department"
                  item-key="text"
                  item-text="name"
                  item-value="id"
                  label=""
                  hide-details
                  dense
                  outlined
                  :disabled="!this.staffPermission.AddUser"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3>สิทธิ์ผู้ใช้แผนก</h3>
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in branchPermissionDepartment" :key="index">
              <v-col  cols="12" md="6" class="py-1 pl-0">
                <v-checkbox  v-model="item.isActive"  @change="selectMenuDepartment(item)" class="ml-4" color="success" :label="item.nameTH" :disabled="!staffPermission.AddUser">
                <template v-slot:label>                     
                  <h4 v-if="item.parentID == null"><b>{{item.nameTH}}</b></h4>
                  <span v-else style="margin-left: 20px;" >{{item.nameTH}}</span>
                </template>
                </v-checkbox>
              </v-col>
              <v-col  cols="12" md="6" class="py-1">
                <v-radio-group
                  v-model="item.authorize"
                  row
                  :disabled="item.isActive == false || !staffPermission.AddUser"
                  class="mb-2 is-cod-selector"
                >
                  <v-radio label="ดู" :value="1">
                  </v-radio>
                  <v-radio label="แก้ไข" :value="2">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
  
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                :elevation="1"
                color="primary"
                class="px-2"
                @click="UpdateStaffBranch()"
              >
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- change password -->
    <v-dialog
      v-model="ChangePasswordDialog"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
          แก้ไขรหัสผ่าน
          <v-spacer />
          <v-btn icon @click="CloseDialog1()"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="formStaffShop1" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3>ชื่อผู้ใช้/รหัสผ่าน</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-2 pb-4">
                <span class="fn-14"> ชื่อ: </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-2 pb-4">
                {{ staffBranch.name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อผู้ใช้ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.username"
                  @change="(value) => (staffBranch.username = Trim_value(value))"
                  @keyup.enter="$refs.staffBranchpassword3.focus()"
                  :rules="staffBranch.rulesUsername"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> รหัสผ่าน : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.password"
                  @change="(value) => (staffBranch.password = Trim_value(value))"
                  ref="staffBranchpassword3"
                  @keyup.enter="$refs.staffBranchpassword4.focus()"
                  :rules="staffBranch.rulesPassword3"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ยืนยันรหัสผ่าน : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffBranch.password2"
                  @change="(value) => (staffBranch.password2 = Trim_value(value))"
                  ref="staffBranchpassword4"
                  :rules="staffBranch.rulesPassword4"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password2 ? 'text' : 'password'"
                  @click:append="show_password2 = !show_password2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="pa-0" align="right">
              <v-btn :elevation="1" color="primary" @click="ChangePassword()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add/edit department -->
    <v-dialog v-model="staffSBranchDepartment.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
           <span v-if="staffSBranchDepartment.type == 0" class="ml-3">เพิ่มแผนก</span>
           <span v-if="staffSBranchDepartment.type == 1" class="ml-3">แก้ไขแผนก </span>
          <v-spacer/>
          <v-btn icon @click="CloseDialogDepartment()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="formStaffShop" lazy-validation>
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อแผนก : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffSBranchDepartment.name"
                  @change="value => staffSBranchDepartment.name = Trim_value(value)"
                  ref="staffDepartmentBranchName"
                  autocomplete="off"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <h3 class="fn-14"> สิทธิ์ผู้ใช้แผนก : </h3>
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in branchPermissionDepartment" :key="index">
              <v-col  cols="12" md="6" class="py-1">
                <v-checkbox  v-model="item.isActive"  @change="selectMenuDepartment(item)" class="ml-4" color="success" :label="item.nameTH">
                <template v-slot:label>                     
                  <h4 v-if="item.parentID == null"><b>{{item.nameTH}}</b></h4>
                  <span v-else style="margin-left: 20px;" >{{item.nameTH}}</span>
                </template>
                </v-checkbox>
              </v-col>
              <v-col  cols="12" md="6" class="py-1">
                <v-radio-group
                  v-model="item.authorize"
                  row
                  :disabled="item.isActive == false"
                  class="mb-2 is-cod-selector"
                >
                  <v-radio label="ดู" :value="1">
                  </v-radio>
                  <v-radio label="แก้ไข" :value="2">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <!-- <v-col  cols="12" md="3" >
              <span   v-if="staffShopDepartment.type == 1" class="fn-14">อัพเดทสิทธิ์ทั้งหมด : </span>
            </v-col>
            <v-col  cols="12" md="3" >
              <v-checkbox   v-if="staffShopDepartment.type == 1"  v-model="staffShopDepartment.isUpdate"   hide-details  color="success"  >
              </v-checkbox>
            </v-col> -->
            <v-col cols="12" md="6"  class="pa-0" align="right">
              <v-btn :elevation="1" color="primary" @click="btnSubmitDialogDepartment()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      left
    >
      <v-tab> ผู้ใช้ </v-tab>
      <v-tab> แผนก </v-tab>
      <v-tab-item>
        <v-card elevation="1">
          <v-card-title class="height-57">
            <v-icon size="22" color="teal darken-3">fa-address-card</v-icon
            ><span class="ml-3">ผู้ใช้</span>
            <v-spacer />
            <v-btn
              v-if="staffPermission.AddUser || LoginstaffBranch.isMasterUser"
              :elevation="1"
              color="primary"
              class="px-2"
              @click="OPPUser(false, null)"
            >
              <v-icon size="22" class="mr-1">mdi-plus</v-icon>
              เพิ่มผู้ใช้
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="header"
              :items="dataset"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table hidden-xs-only"
            >
              <template v-slot:item.photaLink="{ item }">
                <a
                  v-if="item.photoLink != null && item.photoLink != ''"
                  icon
                  @click="OpenPhoto(item.photoLink)"
                >
                  <img :src="item.photoLink" class="picsize-35 mt-1" />
                </a>
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }}
                <v-chip v-if="item.isMasterUser" class="chip-set-13" color="teal" dark>
                  Master User
                </v-chip>
              </template>
              <template v-slot:item.no="{ item }">
                {{ formatMoney(dataset.indexOf(item) + 1) }}
              </template>
              <template v-slot:item.password="{ item }"> ******** </template>
              <template v-slot:item.edit="{ item }">
                <a
                  @click="OPPUser(true, item)"
                  v-if="
                    (item.isMasterUser && LoginstaffBranch.isMasterUser) || !item.isMasterUser
                  "
                  ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
                >
              </template>
              <template v-slot:item.delete="{ item }">
                <a v-if="!item.isMasterUser" @click="DeleteUser(item)">
                  <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                </a>
              </template>
              <template v-slot:item.changepassword="{ item }">
                <a
                  v-if="
                    staff_id == item.id ||
                    staffBranch.isMasterUser ||
                    (thisPagePermissionAllowed && !item.isMasterUser)
                  "
                  @click="OpenChangePassword(item)"
                  >เปลี่ยนรหัสผ่าน</a
                >
              </template>
            </v-data-table>

            <!-- Mobile hidden-sm-and-up -->
            <v-data-iterator
              :items="dataset"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
              <template v-slot:default="props">
                <v-row class="ma-0">
                  <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                    <v-card>
                      <v-card-title class="subheading font-weight-bold pa-0">
                        <div class="px-0 v-list-item item-stert">
                          <a
                            v-if="item.photoLink != null && item.photoLink != ''"
                            @click="OpenPhoto(item.photoLink)"
                            class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile"
                          >
                            <img :src="item.photoLink" class="picsize" />
                          </a>
                          <a
                            v-else
                            class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                            style="height: 90px; min-width: 90px; width: 90px"
                          >
                            <span class="bold-color-gray-12"> No Picture </span>
                          </a>
                          <div class="v-list-item__content py-2">
                            <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>ชื่อ</div>
                                <div style="line-height: 1rem !important">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on" class="cut-text">
                                        <span v-if="item.name != null"
                                          >: {{ item.name }}</span
                                        >
                                      </div>
                                    </template>
                                    <span v-if="item.name != null">{{ item.name }}</span>
                                  </v-tooltip>
                                  <v-chip
                                    v-if="item.isMasterUser"
                                    class="chip-set-13"
                                    color="teal"
                                    dark
                                  >
                                    Master User
                                  </v-chip>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="v-markdown subtitle-1 packhai-showproduct-2">
                                <div>
                                  <div>เบอร์โทร</div>
                                  <div class="cut-text">: {{ item.phone }}</div>
                                </div>

                                <br />
                                <div>
                                  <div>ชื่อผู้ใช้</div>
                                  <div class="cut-text">: {{ item.username }}</div>
                                </div>

                                <br />
                                <div>
                                  <div>รหัสผ่าน</div>
                                  <div class="cut-text">: ********</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon size="18">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list class="v_list_dropdown">
                                <v-list-item
                                  v-for="(i, index) in CheckDropdown(item.isMasterUser)"
                                  :key="index"
                                >
                                  <v-list-item-title @click="Letgo(i.title, true, item)">{{
                                    i.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card elevation="1">
          <v-card-title class="height-57">
            <v-icon size="22" color="teal darken-3">fa fa-sitemap</v-icon
            ><span class="ml-3">แผนก</span>
            <v-spacer />
            <v-btn
             @click="OpenDialogDepartment(0, 0)"
              :elevation="1"
              color="primary"
              class="px-2"
            >
              <v-icon size="22" class="mr-1">mdi-plus</v-icon>
              เพิ่มแผนก
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="header_department"
              :items="dataset_department"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(dataset_department.indexOf(item) + 1) }}
              </template>
              <template v-slot:item.edit="{ item }">
                <a @click="OpenDialogDepartment(0, item.id, item.name)">
                  <v-icon size="16" color="orange darken-3">fa-edit</v-icon>
                </a>
              </template>
              <template v-slot:item.delete="{ item }">
                <a @click="deleteDepartment(item.id)">
                  <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import AvatarCropper from "vue-avatar-cropper";
import { shopService_dotnet } from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  formatMoney,
  isNumberWNoDot,
  isNumberWithDot,
  DateNowNotNull,
  Trim_value,
  Trim_rules,
} from "@/website/global_function";
import { generalService_dotnet, branchService_dotnet } from "../../global";
export default {
  components: {
    Loading,
    AvatarCropper,
  },
  data: () => ({
    tab:null,
    header_department: [
      { align: "center", text: "No", value: "no", sortable: false, width: "60px" },
      { align: "left", text: "ชื่อแผนก", value: "name", sortable: false },
      { align: "center", text: "แก้ไข", value: "edit", sortable: false, width: "80px" },
      { align: "center", text: "ลบ", value: "delete", sortable: false, width: "80px" },
    ],
    dataset_department: [],
    branchPermissionDepartment: [],
    staffSBranchDepartment:{
      dialog: false,
      tpye:0,
      name:null,
      rulesName: [],
      id:0,
      isUpdate:false,
    },

    // page
    ChangePasswordDialog: false,
    page_loading: true,
    loading: false,
    thisPagePermissionAllowed: false,
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",
    BranchID: localStorage.getItem("Branch_BranchID"),
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    staffPermission: JSON.parse(localStorage.getItem("permission")),
    // user
    LoginstaffBranch: {
      isMasterUser: false,
      id: 0,
    },
    staffBranch: {
      dialog: false,
      isUpdate: false,
      isReadonly: false,
      rulesName: [],
      rulesPhone: [],
      rulesUsername: [],
      rulesPassword: [],
      rulesPassword2: [],
      rulesPassword3: [],
      rulesPassword4: [],
      branchId: localStorage.getItem("Branch_BranchID"),
      id: 0,
      name: null,
      phone: null,
      username: null,
      password: null,
      password2: null,
      isMasterUser: false,
      isActive: true,
      photoLink: null,
      department: 0,
    },
    staff_id: localStorage.getItem("Branch_StaffID"),
    show_password: false,
    show_password2: false,
    branchPermission: [],
    header: [
      { align: "center", text: "No", value: "no", sortable: false, width: "60px" },
      {
        align: "center",
        text: "รูปภาพ",
        value: "photaLink",
        sortable: false,
        width: "70px",
      },
      { align: "left", text: "ชื่อ", value: "name", sortable: false },
      {
        align: "center",
        text: "เบอร์โทร",
        value: "phone",
        sortable: false,
        width: "150px",
      },
      {
        align: "center",
        text: "ชื่อผู้ใช้",
        value: "username",
        sortable: false,
        width: "150px",
      },
      { align: "center", text: "แก้ไข", value: "edit", sortable: false, width: "80px" },
      { align: "center", text: "ลบ", value: "delete", sortable: false, width: "80px" },
      {
        align: "center",
        text: "เปลี่ยนรหัสผ่าน",
        value: "changepassword",
        sortable: false,
        width: "120px",
      },
    ],
    dataset: [],
    set_language: "th",
    // AvatarCropper
    cropperOptions: {
      background: false,
      viewMode: 1,
      aspectRatio: 1,
      autoCropArea: 1,
      movable: false,
      zoomable: false,
    },
    textButton: {
      submit: "ตกลง",
      cancel: "ยกเลิก",
    },
    uploadurl: generalService_dotnet + "Picture/upload-file-with-bucket",
    message: "ready",
    photo: {
      is_base: false,
      photo_link: null,
      photo_uploand: {
        file: [],
        fileSize: 0,
        from_Picture: {},
        from_path: "",
      },
    },
  }),
  computed: {},
  watch: {},
  async created() {
    //
    this.GetStaffBranch();
    let response = await axios.post(
      shopService_dotnet + "StaffBranch/get-staffbranchpermission",
      {
        staffBranchId: this.staff_id,
      },
      { headers: header_token }
    );
    if (response.status == 200) {
      // แก้ไขผู้ใช้

      for (var l in response.data) {
        if (response.data[l].staffBranchPermissionID == 2) {
          this.thisPagePermissionAllowed = true;
          break;
        }
      }
    }

    this.getDepartment()
    this.page_loading = false;
  },
  methods: {
    //department
    async getPermission() {
      let response = await axios.post(
        branchService_dotnet + "Department/get-branch-permission",
        { headers: header_token }
      );
      this.branchPermissionDepartment = response.data
    },

    async OpenDialogDepartment(type, id, name) {
      this.loading = true
      this.staffSBranchDepartment.type = type
      this.staffSBranchDepartment.id = id
      
      if (id == 0) {
        await this.getPermission()
      }

      if(id != 0) {
        let isActivePermission = await this.getPermissionDepartment(id);

        this.staffSBranchDepartment.name = name

        let setShowPermission = isActivePermission.permissionList
        .map((item) => ({ ...item, isActive: false, authorize: 2 }));
        
        const permissionMap = new Map(
          isActivePermission.branchPermissionList.map((item) => [item.permissionID, item])
        );

        setShowPermission = setShowPermission.map((item) => {
          const matchedPermission = permissionMap.get(item.id);
          if (matchedPermission) {
            return {...item, isActive: true, authorize: matchedPermission.authorize};
          }
          return item;
        });

        this.branchPermissionDepartment = setShowPermission
        
      }
      this.staffSBranchDepartment.dialog = true
      this.loading = false
    },

    CloseDialogDepartment() {
      this.staffSBranchDepartment = {
        dialog: false,
        tpye:0,
        name:null,
        rulesName: [],
        id:0,
        isUpdate:false,
      }
      this.branchPermissionDepartment = []
    },

    selectMenuDepartment (item) {
      if (item.parentID == null) {
        var activeChlid = this.branchPermissionDepartment.filter((data) => data.parentID == item.id )
        for(var Chlid of activeChlid){
          Chlid.isActive = item.isActive;
        }
      } else {
        var disableParent = this.branchPermissionDepartment.filter((data) => data.parentID == item.parentID && data.isActive)
        var countActive = disableParent.length;
        if(countActive <= 0) {
          var parentNot = this.branchPermissionDepartment.find((data) => data.id == item.parentID)
          parentNot.isActive = false;
        } else {
          var parentActive = this.branchPermissionDepartment.find((data) => data.id == item.parentID)
          parentActive.isActive = true;
        }
      }       
    },

    async getDepartment() {
      let response = await axios.post(
        branchService_dotnet + "Department/get-branch-department",
        {
          "branchID": this.BranchID,
        },
        { headers: header_token }
      );
      this.dataset_department = response.data
    },

    async getPermissionDepartment(id) {
      let response = await axios.post(
        branchService_dotnet + "Department/get-permission-department",
        {
          "departmentID": id,
        },
        { headers: header_token }
      );
      return response.data
    },

    async btnSubmitDialogDepartment(){
      if(this.staffSBranchDepartment.name == null || this.staffSBranchDepartment.name == '') {
        this.$refs.staffDepartmentBranchName.focus()
        this.errorAlert("กรุณากรอกชื่อแผนก")
        return
      }
      this.loading = true;
      let body = [];
      let tempRoleDepartmet = this.branchPermissionDepartment.filter(data => data.isActive == true)
      .map(
        (item) => {
          return {...item, permissionID: item.id, authorize: item.authorize}
        }
      );
      body.name = this.staffSBranchDepartment.name
      body.id  = this.staffSBranchDepartment.id ;
      body.branchPermissionDepartment = tempRoleDepartmet

      let res = await this.manageDepartment(body);

      if(res.status == "success"){
        await this.getDepartment();
        this.successAlert();
      }
      
      this.staffSBranchDepartment.name = null;
      this.staffSBranchDepartment.id = 0;
      this.staffSBranchDepartment.dialog = false;

      this.loading = false;
      
    },

    async manageDepartment(body) {
      return await axios.post(
        branchService_dotnet + "Department/manage-department",
        {
          "branchID": this.BranchID,
          "departmentID": body.id,
          "departmentName": body.name,
          "departmentList": body.branchPermissionDepartment
        },
        { headers: this.header_token }
      )
      .then(async (res) => { return res.data })
      .catch((error) => {
        this.AlertError();
        return false;
      });
    },

    async deleteDepartment(id){
      this.loading = true;
      let response = await axios.post(
        branchService_dotnet + "Department/delete-department",
        {
          "departmentID": id,
        },
        { headers: header_token }
      );
      if(response.data.status == "success"){
        await this.getDepartment();
        this.successAlert();
      }
      this.loading = false;
    },

    async updateChangeDepartment(id){
      let isActivePermission = await this.getPermissionDepartment(id);

      this.staffSBranchDepartment.name = name

      let setShowPermission = isActivePermission.permissionList
      .map((item) => ({ ...item, isActive: false, authorize: 2 }));
      
      const permissionMap = new Map(
        isActivePermission.branchPermissionList.map((item) => [item.permissionID, item])
      );

      setShowPermission = setShowPermission.map((item) => {
        const matchedPermission = permissionMap.get(item.id);
        if (matchedPermission) {
          return {...item, isActive: true, authorize: matchedPermission.authorize};
        }
        return item;
      });

      this.branchPermissionDepartment = setShowPermission
      
    },

    async GetStaffBranchPesrmissionMenu(id) {
      let response = await axios.post(
        shopService_dotnet + "StaffBranch/get-staffbranchpermission-menu",
        {
          staffBranchId: id
        },
        { headers: header_token }
      );
      this.staffBranch.department = response.data.departmentID

      if(response.data.items.length > 0) {
        let isActivePermission = await response.data.items;

        let setShowPermission = this.branchPermissionDepartment
        .map((item) => ({ ...item, isActive: false, authorize: 2 }));
        
        const permissionMap = new Map(
          isActivePermission.map((item) => [item.permissionMenuID, item])
        );

        setShowPermission = setShowPermission.map((item) => {
          const matchedPermission = permissionMap.get(item.id);
          if (matchedPermission) {
            return {...item, isActive: true, authorize: matchedPermission.authorize};
          }
          return item;
        });
        
        this.branchPermissionDepartment = setShowPermission
      }
    },

    // user
    async GetStaffBranch() {
      let response = await axios.post(
        shopService_dotnet + "StaffBranch/get-staffbranch-list",
        {
          branchId: this.BranchID,
        },
        { headers: header_token }
      );
      if (response.status == 200) {
        this.dataset = response.data;

        this.LoginstaffBranch = this.dataset.filter((x) => x.id == this.staff_id)[0];

        if (this.staffPermission.AddUser || this.LoginstaffBranch.isMasterUser) {
          this.dataset = response.data.filter((x) => x.id != 0);
        } else {
          this.dataset = response.data.filter((x) => x.id == this.staff_id && x.id != 0);
        }
      }
    },
    async OpenChangePassword(item) {
      this.staffBranch.rulesPassword3 = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesPassword4 = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesUsername = [(v) => !!v || "กรุณาระบุ"];

      this.staffBranch.id = item.id;
      this.staffBranch.name = item.name;
      this.staffBranch.username = item.username;
      this.staffBranch.password = null;
      this.staffBranch.password2 = null;
      this.ChangePasswordDialog = true;
    },
    async ChangePassword() {
      if (this.$refs.formStaffShop1.validate()) {
        if (this.staffBranch.password != this.staffBranch.password2) {
          Swal.fire({
            icon: "warning",
            title: "รหัสผ่านไม่ตรงกัน",
            text: "กรุณาลองใหม่อีกครั้ง",
          });
          return;
        }

        let response = await axios.post(
          shopService_dotnet + "StaffBranch/update-staff-branch-password",
          {
            id: this.staffBranch.id,
            username: this.staffBranch.username,
            password: this.staffBranch.password,
          },
          { headers: this.header_token }
        );
        this.loading = false;

        if (response.status == 200) {
          if (response.data.status == "error") {
            Swal.fire({
              icon: "error",
              text: response.data.message[this.set_language],
            });
          } else {
            this.dataset = response.data.staffList;
            this.ChangePasswordDialog = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              icon: "success",
              title: "Success",
            });
          }
        } else {
          this.loading = false;
          Swal.fire({
            position: "top",
            icon: "error",
            text: "ไม่สามารถทำรายการได้",
          });
        }
      }
    },
    async OPPUser(isUpdate, item) {
      this.loading = true;
      this.rulesStaffBranch();
      await this.getPermission()

      if (this.branchPermission.length == 0) {
        // โหลดครั้งแรก เพิ่มผู้ใช้ใหม่ แก้ไขผู้ใช้
        let response = await axios.post(
          shopService_dotnet + "StaffBranch/get-permission-description",
          {},
          { headers: header_token }
        );
        if (response.status == 200) {
          for (var i in response.data) {
            response.data[i].isActive = true;
          }
          this.branchPermission = response.data;
        }
      } else {
        if (item == null) {
          // เพิ่มผู้ใช้ใหม่
          for (var j in this.branchPermission) {
            this.branchPermission[j].isActive = true;
          }
        }
      }

      if (!isUpdate) {
        this.staffBranch.id = 0;
        this.staffBranch.name = null;
        this.staffBranch.phone = null;
        this.staffBranch.username = null;
        this.staffBranch.password = null;
        this.staffBranch.password2 = null;
        this.staffBranch.isMasterUser = false;
        this.staffBranch.isActive = true;
        this.staffBranch.photoLink = null;
        this.staffBranch.isReadonly = false;

        // ภาพ
        this.photo.photo_link = null;
        this.photo.is_base = false;

        var isMasterUser = false;
        for (var a in this.dataset) {
          if (this.dataset[a].isMasterUser) {
            isMasterUser = true;
            break;
          }
        }
        if (isMasterUser) {
          this.staffBranch.isMasterUser = !isMasterUser;
        } else {
          this.staffBranch.isMasterUser = !isMasterUser;
        }
      } else {
        this.staffBranch.id = item.id;
        this.staffBranch.name = item.name;
        this.staffBranch.phone = item.phone;
        this.staffBranch.username = item.username;
        this.staffBranch.password = item.password;
        this.staffBranch.password2 = item.password;
        this.staffBranch.isMasterUser = item.isMasterUser;
        this.staffBranch.isActive = item.isActive;
        this.staffBranch.photoLink = item.photoLink;
        this.staffBranch.isReadonly = item.isMasterUser ? true : false;

        // ภาพ
        this.photo.photo_link = item.photoLink;
        this.photo.is_base = true;

        let response = await axios.post(
          shopService_dotnet + "StaffBranch/get-staffbranchpermission",
          {
            staffBranchId: item.id,
          },
          { headers: header_token }
        );
        if (response.status == 200) {
          // แก้ไขผู้ใช้
          for (var k in this.branchPermission) {
            this.branchPermission[k].isActive = false;
            for (var l in response.data) {
              if (
                response.data[l].staffBranchPermissionID == this.branchPermission[k].id
              ) {
                this.branchPermission[k].isActive = true;
                break;
              }
            }
          }
        }
        await this.GetStaffBranchPesrmissionMenu(item.id)
        
      }
      (this.show_password = false),
        (this.show_password2 = false),
        (this.staffBranch.isUpdate = isUpdate);
      await this.getDepartment();
      this.loading = false;
      this.staffBranch.dialog = true;
    },
    async UpdateStaffBranch() {        
      await this.rulesStaffBranchCheck();
      if (this.$refs.formStaffBranch.validate()) {
        if (this.staffBranch.password != this.staffBranch.password2) {
          this.AlertWarning("รหัสผ่านไม่ตรงกัน");
          return;
        }
        this.loading = true;
        if (this.staffBranch.isMasterUser) {
          this.staffBranch.isActive = true;
          for (var i in this.branchPermission) {
            this.branchPermission[i].isActive = true;
          }
        }
        // เช็คภาพ
        var photoLink_del = null;
        if (this.photo.photo_link != this.staffBranch.photoLink) {
          photoLink_del = this.staffBranch.photoLink;
          this.staffBranch.photoLink = this.photo.photo_link;
        }
        try {

          var menu
          if (this.staffBranch.isMasterUser) {
            menu = this.branchPermissionDepartment.map((item) => ({ ...item, isActive: true, authorize: 2 }));
          } else {
            menu = this.branchPermissionDepartment
          }
          let tempRoleDepartmet = menu.filter(data => data.isActive == true)
          .map((item) => { return {...item, permissionID: item.id, authorize: item.authorize} });            
          
          let response = await axios.post(
            shopService_dotnet + "StaffBranch/update-staffbranch",
            {
              staffBranch: this.staffBranch,
              branchPermission: this.branchPermission,
              branchPermissionMenu: tempRoleDepartmet
            },
            { headers: this.header_token }
          );
          this.loading = false;

          if (response.status == 200) {
            if (response.data.status == "error") {
              Swal.fire({
                type: "error",
                text: response.data.message[this.set_language],
              });
            } else {
              // ลบ ภาพ
              if (photoLink_del != null) {
                await this.DelPhoto(photoLink_del, false);
              }
              this.dataset = response.data.staffList;
              this.staffBranch.dialog = false;
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: "top",
                icon: "success",
                title: "Success",
              });
            }
          } else {
            Swal.fire({
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            this.bt_logout_click();
          }
        }
      }
    },
    async DeleteUser(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: item.name + " ลบ ใช่ หรือ ไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            shopService_dotnet + "StaffBranch/delete-staffbranch",
            {
              id: item.id,
              staffBranchId:this.staff_id,
            },
            { headers: header_token }
          );
          this.loading = false;
          if (response.status == 200) {
            this.dataset.splice(this.dataset.indexOf(item), 1);
            this.AlertSuccess();
          } else {
            this.AlertError();
          }
        }
      });
    },
    async CloseDialog() {
      await this.DelPhoto(this.photo.photo_link, this.photo.is_base);
      this.staffBranch.dialog = false;
      this.staffBranch.department = 0;
      this.CloseDialogDepartment()
    },
    async CloseDialog1() {
      this.ChangePasswordDialog = false;
    },
    rulesStaffBranchCheck() {
      this.staffBranch.rulesName = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesPhone = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesUsername = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesPassword = [(v) => !!v || "กรุณาระบุ"];
      this.staffBranch.rulesPassword2 = [(v) => !!v || "กรุณาระบุ"];
    },
    rulesStaffBranch() {
      this.staffBranch.rulesName = [];
      this.staffBranch.rulesPhone = [];
      this.staffBranch.rulesUsername = [];
      this.staffBranch.rulesPassword = [];
      this.staffBranch.rulesPassword2 = [];
    },

    // AvatarCropper
    async handleUploading(from_data, xhr) {
      from_data.append("bucket", "staffbranch");
      this.photo.photo_uploand["from_Picture"] = from_data;
      this.message = "uploading...";
    },
    async handleUploaded(response, from_data, xhr) {
      await this.DelPhoto(this.photo.photo_link, this.photo.is_base);
      this.photo.photo_link = response.file_path;
      this.photo.photo_uploand["from_path"] = response.file_path;
      this.photo.is_base = false;
      this.message = "user avatar updated.";
    },
    handleCompleted(response, from_data, xhr) {
      this.message = "upload completed.";
    },
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong...";
    },
    async DelPhoto(photoLink, is_base) {
      if (photoLink != "" && photoLink != null && !is_base) {
        let response = await axios.post(
          generalService_dotnet + "General/delete-file",
          {
            url: photoLink,
          },
          { headers: this.header_token }
        );
      }
    },

    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    CheckDropdown(isMasterUser) {
      var result = [{ title: "แก้ไข" }];
      if (!isMasterUser) result.push({ title: "ลบ" });
      return result;
    },
    Letgo(text, isUpdate, item) {
      if (text === "แก้ไข") this.OPPUser(isUpdate, item);
      else this.DeleteUser(item);
    },
    formatMoney,
    isNumberWNoDot,
    isNumberWithDot,
    DateNowNotNull,
    Trim_value,
    Trim_rules,
    AlertSuccess,
    AlertWarning,
    AlertError,

    // alert
    async successAlert(){
      Swal.fire({
        toast: true,
        timer: 1500,
        showConfirmButton: false,
        type: 'success',
        title: 'Success'
      })
    },
    async errorAlert(body){
      Swal.fire({
        type: 'error',
        text: body,
      })
    },
  },
};
</script>
